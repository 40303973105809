import ToPay from './components/ToPay'
import CurrentBalance from './components/CurrentBalance'
import RemainingBalance from './components/RemainingBalance'
import PaymentList from './components/PaymentList'
import { useState, useEffect } from 'react'
import './App.css';

function App() {

  const payments = [
    {id:"cloud1", description: "Apple - Cloud storage", amount: 2.49, day:28, category: "Credit Card", paid:false},
    {id:"ctax", description: "Council Tax", amount: 193.00, day:2, category: "Direct Debit", paid:false},
    {id:"tvlic", description: "TV License", amount: 13.25, day:1, category: "Direct Debit", paid:false},
    {id:"lifeIns", description: "Virgin Life Insurance", amount: 20.50, day:1, category: "Direct Debit", paid:false},
    {id:"shop1", description: "Big Shop -1", amount: 80.00, day:1, category: "Ad Hoc", paid:false},
    {id:"guardian", description: "Apple Sub - The Guardian", amount: 9.99, day:2, category: "Credit Card", paid:false},
    {id:"mobile", description: "O2 Mobile and Disney+", amount: 20.86, day:2, category: "Direct Debit", paid:false},
    {id:"rent", description: "Rent - House", amount: 1490.00, day:10, category: "Direct Debit", paid:false},
    {id:"spotify", description: "Spotify", amount: 19.99, day:12, category: "Credit Card", paid:false},
    {id:"shop2", description: "Big Shop -2", amount: 80.00, day:15, category: "Ad Hoc", paid:false},
    {id:"houseIns", description: "Admiral Contents Insurance", amount: 6.74, day:20, category: "Direct Debit", paid:false},
    {id:"nowent", description: "Now TV Entertainment", amount: 6.99, day: 2, category: "Credit Card", paid:false},
    {id:"nowboost", description: "Now TV Boost", amount: 6.00, day: 2, category: "Credit Card", paid:false},
    {id:"nowcin", description: "Now TV Cinema", amount: 4.99, day: 12, category: "Credit Card", paid:false},
    {id:"nowsport", description: "Now TV Sports", amount: 19.99, day: 5, category: "Credit Card", paid:false},
    {id:"Microsoft", description: "Microsoft Ultimate", amount: 12.99, day: 1, category: "Credit Card", paid:false},
    {id:"Playstation", description: "Playstation Plus", amount: 9.99, day: 23, category: "Credit Card", paid:false},
    {id:"ink", description: "HP Instant Ink", amount: 3.99, day: 30, category: "Credit Card", paid:false},
    {id:"iphone", description: "iPhone payments", amount: 33.29, day: 13, category: "Credit Card", paid:false},
  ]

  const [data, setData] = useState(payments);
  const [leftToPay, setLeftToPay ] = useState(0)
  const [ balance, setBalance ] = useState(0)
  const [ total, setTotal ] = useState(0)


  function getTodaysDate(data) {
    const currentDate = new Date().getDate();
    /* const currentDate=10 */
    const updatedData = data.map(item => {
      let shouldMarkAsPaid = false;
  
      if (currentDate < 28) {
        // If today is before the 28th, mark payments due after the last 28th (which means less than today's date)
        // and payments that are due before today in the current month as paid.
        shouldMarkAsPaid = item.day >= 28 || item.day < currentDate;
      } else {
        // If today is the 28th or after, mark payments due from the 28th to today as paid.
        shouldMarkAsPaid = item.day >= 28 && item.day <= currentDate;
      }
  
      return shouldMarkAsPaid ? { ...item, paid: true } : item;
    });
  
    setData(updatedData);
  }

  function updatePaidStatus(clickedID) {
    const index = data.findIndex(item => item.id === clickedID);
    if (index !== -1) {
      const updatedItem = {...data[index], paid: !data[index].paid};
      const updatedItems = [...data];
      updatedItems[index] = updatedItem;
      setData(updatedItems)
    }
  }

  var runningTotal;

  function totalUnpaid(data) {
    runningTotal = 0;
    for(var i=0; i < data.length; i++) {
      if(data[i].paid === false) {
        let thisAmount = data[i].amount
        runningTotal = (Math.round((runningTotal*100) + (thisAmount*100))/100)
      }
    }
    return runningTotal;
  }

  function finalBalance(currentBalance, currentLeftToPay) {
    setTotal((Math.round((currentBalance*100) - (currentLeftToPay*100))/100))
  }

  function handleBalanceChange(event){
    setBalance(event.target.value)
  }

  useEffect(function() {
    getTodaysDate(data)
  },[])

  useEffect(function() {
    totalUnpaid(data)
  },[data])

  useEffect(function() {
    setLeftToPay(runningTotal)
  },[data])

  useEffect(function() {
    finalBalance(balance, leftToPay)
  },[balance,leftToPay])
  

  return (
    <div className="App">
      <CurrentBalance balance={balance} changeBalance={handleBalanceChange}/>
      <ToPay amount={leftToPay}/>
      <RemainingBalance finalBalance={total}/>
      <PaymentList data={data} updatePaid={updatePaidStatus} />
    </div>
  );
}

export default App;
