import './PaymentList.css'

export default function PaymentList({data, updatePaid}) {
  return(
    <div className="payment-list">
      <ul>
        {data.map(item => (
          <li className={item.paid ? "status-paid" : "status-unpaid"} key={item.id} onClick={(e) => updatePaid(item.id)}><p>{item.day}</p><p>{item.description}</p><p>£{item.amount}</p></li>
        ))}
      </ul>
    </div>
  )
}